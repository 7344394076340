$desktop-width: 1024px;


ion-app {
  @media (min-width: $desktop-width) { 
      max-width: 375px;
      width: 375px;
      margin: 0 auto;
  }
  @media (min-height: 812px) and (min-width: $desktop-width) {
    height: 812px;
    min-height: 812px;
  }
}


p, ul {
  font-size: 6.25vw;
  margin: 9vw 0 0;
  line-height: 1.3;
  @media (min-width: $desktop-width) { 
    font-size: 23px;
    margin: 30px 0 0;
  }
}

ion-toolbar {
  --border-width: 0 !important;
}

.button {
  display: block !important;
  --border-radius: 0 !important;
  --box-shadow: none !important;

  .button-native {
    border-radius: 0 !important;
    line-height: 1.25 !important;
  }
  &.helpme {
    width: 60%;
    margin: 0 auto;
  }

}

ion-router-link.button {
  display: block;
  text-transform: uppercase;
  font-family: "apercu-mono";
  letter-spacing: 0.025em;
  color: #000;
  background: transparent;
  font-weight: bold;
  font-size: 5vw;
  padding: 5vw;
  line-height: 1.5;
  text-align: center;
  margin: 1.5em 0 0;  
  height: auto;
  white-space: normal;
  border: 3px solid #fff;

  @media (min-width: $desktop-width) { 
    font-size: 18px;
    display: inline-block;
    padding: 18px;
    cursor: pointer;
  }

  &.w-o-b {
    color: #fff;
    background: #000;
    border-color: #fff;
  }

  &.home-create {
    margin-top: 20vw;

    @media (min-width: $desktop-width) { 
      margin-top: 75px;
    }
    ion-icon {
      font-size: 0.8em;
    }
  }

  &.secondary {
    border: none;
    color: #fff;
    margin-top: 0;
  }
}

.modal-wrapper .ion-page {
  padding: 0;
  background: #fff;

  .button.close {
    position: absolute;
    top: 5vw;
    right: 5vw;
    padding: 0;
    margin: 0;
    font-size: 12vw;
    border: none;
    @media (min-width: $desktop-width) { 
      font-size: 45px;
      top: 18px;
      right: 18px;
    }
  }
}

ion-content {
  --offset-left: 30px !important;
  min-height: 600px;
  &.ion-padding {
    position: relative;
  }

  .section {
    padding-top: 15vw;
    padding-bottom: 20vw;
    @media (min-width: $desktop-width) { 
      padding-top: 56px;
      padding-bottom: 75px;
    }
  }

  & + ion-content {
    --offset-top: 100px;
  }

  > div.align-bottom {
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
    min-height: 100%;
    padding-bottom: 10vw;

    @media (min-width: $desktop-width) { 
      
    }

    .scroll-container {
      margin: auto 0 0 0;
    }
  }


}


ion-title, h1, h2, h3, h4 {
  margin-top: 9vw;
  font-family: 'GillSans';

  @media (min-width: $desktop-width) { 
    margin-top: 33px;
  } 

  &.title-default {
    display: none;
  }
}

.ion-page {
  background: #4D84FA;

  @media (min-width: $desktop-width) { 
    
  }

  ion-toolbar {
    --background: #000 !important;
  }

  ion-content, ion-header {
    --background: transparent !important;

    ion-back-button, ion-button {
      color: #fff;
      --color: #fff;
    }
  }

  &.black {
    background: #000;
    p {
      --color: #fff;
      color: #fff;
    }
  }
}

#main-nav {
  display: none;
}

#main-nav, #main-nav ion-tab-button {
  background: #D0C7C0;
  height: 80px;
  border: none;
  color: #000;
  text-transform: uppercase;
}

#main-nav ion-tab-button {
  display: flex;
  justify-content: space-between;
  padding: 2% 0 2%;
}

#main-nav ion-tab-button ion-label {
  font-weight: bold;
}

h1 {
  font-size: 9.35vw;
  line-height: 1.1;

  @media (min-width: $desktop-width) { 
    font-size: 35px;
  } 

  &.topicTitle {
    font-size: 2.5rem;
    color: #000;
  }

  &.xl {
    font-size: 12vw;

    @media (min-width: $desktop-width) { 
      font-size: 45px;
    } 
  }

  &.xxl {
    font-size: 16vw;
    @media (min-width: $desktop-width) { 
      font-size: 60px;
    } 
  }
}

h2 {
  font-size: 8vw;
  line-height: 1.14;
  @media (min-width: $desktop-width) { 
    font-size: 30px;
  } 
}

h3 {
  font-size: 1.5em;
}

[contenteditable] {
  user-select: text; 
  -webkit-user-select: auto;
}

.unstuck-options {
  list-style: none;
  padding: 0;

  li, p {
    margin: 0;
    padding: 0;

    font-family: "GillSans";
    font-size: 2.25rem;
  }
  p {
    margin: 1em 0;
    font-size: 2.25rem;
  }

  li {
    &:nth-of-type(5n+1) {
      color: #005FB5;
    }
    &:nth-of-type(5n+2) {
      color: #4D84FA;
    }
    &:nth-of-type(5n+3) {
      color: #F5ABCF;
    }
    &:nth-of-type(5n+4) {
      color: #ADABD4;
    }
    &:nth-of-type(5n+5) {
      color: #FA4F39;
    }
  }
}

textarea.magic-text-area {
  padding: 0;
  min-height: 35vw;
  margin: 10vw 0;
  font-size: 1.2em;
  line-height: 1.3;
  font-family: "apercu-mono";
  caret-color: #fff;
  background: transparent;
  outline: none;
  width: 100%;
  border: 0;
  resize: none;

  @media (min-width: $desktop-width) { 
    min-height: 131px;
    margin: 37px 0;
  } 

  &:empty:not(:focus):before{
    content:attr(data-ph);
  }

  &:after {
    display: inline-block;
    content: "|";
    animation: blink-empty 2s infinite;
    color: #fff;
    width: 4px;
    letter-spacing: 0;
    background-color: #fff;
    color: transparent;
  }

  &::placeholder {
    color: #000;
    opacity: 0.4;
  }
}

@keyframes blink-empty {
  0% {opacity: 1;}
  50% {opacity: 0;}
}

.shameGuide {
  .shameGuideHeader {
    background-color: #F5ABCF;
  }
  .shameGuideContent, .shameStoryWrapper {
    background: #4D84FA;
  }
  .shameStoryReview {
    background: #ADABD4;
  }
}

.shapeContainer {
  position: relative;
  height: 230vw;

  @media (min-width: $desktop-width) {
    height: 862px;
  }

  &.breathe-shapes {
    height: 190vw;
    
    @media (min-width: $desktop-width) {
      height: 712px;
    }
  }

  &.worth-shapes {
    height: 300vw;

    @media (min-width: $desktop-width) {
      height: 1125px;
    }
  }

  &.definefeelings-shapes {
    height: 300vw;

    @media (min-width: $desktop-width) {
      height: 1125px;
    }
  }

  .shape {
    color: #000;
    font-family: "apercu-mono";
    font-weight: bold;
    font-size: 5.3vw;

    @media (min-width: $desktop-width) {
      font-size: 20px;
    }
  
    &.shape-round-blob {
      position: absolute;
      width: 70vw;
      height: 70vw;
      background-image: url('/assets/shape-round-blob.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -15vw;
      top: 0;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12vw 12vw 18vw 18vw;

      @media (min-width: $desktop-width) {
        width: 262px;
        height: 262px;
        right: -56px;
        padding: 45px 45px 67px 67px;
      }
    }

    &.shape-spikey-blob {
      position: absolute;
      width: 100vw;
      height: 100vw;
      background-image: url('/assets/shape-spikey-blob.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      left: -30vw;
      top: 50vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12vw 12vw 18vw 18vw;

      @media (min-width: $desktop-width) {
        width: 375px;
        height: 375px;
        top: 187px;
        left: -112px;
        padding: 45px 45px 67px 67px;
      }
    }

    &.shape-puddle-blob {
      position: absolute;
      width: 110vw;
      height: 110vw;
      background-image: url('/assets/shape-puddle-blob.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -30vw;
      top: 130vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5vw 20vw 25vw 30vw;

      @media (min-width: $desktop-width) {
        width: 412px;
        height: 412px;
        right: -112px;
        top: 487px;
        padding: 18px 75px 93px 112px;
      }
    }

    &.shape-breathe-1 {
      position: absolute;
      width: 70vw;
      height: 55vw;
      background-image: url('/assets/shape-breathe-1.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 5vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 12vw 10vw 12vw;

      @media (min-width: $desktop-width) {
        width: 262px;
        height: 206px;
        right: -24px;
        top: 18px;
        padding: 0 45px 37px 45px;
      }
    }

    &.shape-breathe-2 {
      position: absolute;
      width: 80vw;
      height: 120vw;
      background-image: url('/assets/shape-breathe-2.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      left: -6.6vw;
      top: 27vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0vw 12vw 25vw 8vw;
      z-index: 9;
      @media (min-width: $desktop-width) {
        width: 300px;
        height: 450px;
        left: -24px;
        top: 101px;
        padding: 0 45px 93px 30px;
      }
    }
    &.shape-breathe-3 {
      position: absolute;
      width: 113.2vw;
      height: 110vw;
      background-image: url('/assets/shape-breathe-3.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 85vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5vw 25vw 15vw 40vw;
      z-index:7;
      @media (min-width: $desktop-width) {
        width: 425px;
        height: 412px;
        right: -24px;
        top: 318px;
        padding: 18px 93px 56px 150px;
      }
    }

    &.shape-boundaries-1 {
      position: absolute;
      width: 85vw;
      height: 75vw;
      background-image: url('/assets/shape-boundaries-1.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 5vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10vw 10vw 20vw;
      z-index: 9;
      @media (min-width: $desktop-width) {
        width: 318px;
        height: 281px;
        right: -24px;
        top: 18px;
        padding: 0 37px 37px 75px;
      }
    }

    &.shape-boundaries-2 {
      position: absolute;
      width: 100vw;
      height: 120vw;
      background-image: url('/assets/shape-boundaries-2.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      left: -6.6vw;
      top: 35vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15vw 20vw 0vw 10vw;
      z-index: 4;
      @media (min-width: $desktop-width) {
        width: 375px;
        height: 450px;
        left: -24px;
        top: 131px;
        padding: 56px 75px 0vw 37px;
      }
    }
    &.shape-boundaries-3 {
      position: absolute;
      width: 106.6vw;
      height: 110vw;
      background-image: url('/assets/shape-boundaries-3.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 94vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25vw 10vw 0 16.6vw;
      z-index:3;
      @media (min-width: $desktop-width) {
        width: 399px;
        height: 412px;
        right: -24px;
        top: 352px;
        padding: 93px 37px 0 62px;
      }
    }

    &.shape-worth-1 {
      position: absolute;
      width: 85vw;
      height: 75vw;
      background-image: url('/assets/shape-boundaries-1.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 5vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10vw 10vw 20vw;
      z-index: 9;
      @media (min-width: $desktop-width) {
        width: 318px;
        height: 281px;
        right: -24px;
        top: 18px;
        padding: 0 37px 37px 75px;
      }
    }

    &.shape-worth-2 {
      position: absolute;
      width: 80vw;
      height: 95vw;
      background-image: url('/assets/shape-boundaries-2.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      left: -6.6vw;
      top: 35vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10vw 40vw 0vw 10vw;
      z-index: 4;
      @media (min-width: $desktop-width) {
        width: 300px;
        height: 356px;
        left: -24px;
        top: 131px;
        padding: 37px 150px 0vw 37px;
      }
    }
    &.shape-worth-3 {
      position: absolute;
      width: 106.6vw;
      height: 110vw;
      background-image: url('/assets/shape-boundaries-3.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 90vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25vw 10vw 0 16.6vw;
      z-index:3;
      @media (min-width: $desktop-width) {
        width: 399px;
        height: 412px;
        right: -24px;
        top: 337px;
        padding: 93px 37px 0 62px;
      }
    }

    &.shape-worth-4 {
      position: absolute;
      width: 106.6vw;
      height: 135vw;
      background-image: url('/assets/shape-worth-4.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 150vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20vw 20vw 0 40vw;
      z-index:3;
      @media (min-width: $desktop-width) {
        width: 399px;
        height: 506px;
        right: -24px;
        top: 562px;
        padding: 75px 75px 0 150px;
      }
    }

    &.shape-definefeelings-1 {
      position: absolute;
      width: 90vw;
      height: 75vw;
      background-image: url('/assets/shape-definefeelings-1.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 5vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10vw 10vw 20vw;
      z-index: 3;
      @media (min-width: $desktop-width) {
        width: 337px;
        height: 281px;
        right: -24px;
        top: 18px;
        padding: 0 37px 37px 75px;
      }
    }

    &.shape-definefeelings-2 {
      position: absolute;
      width: 100vw;
      height: 105vw;
      background-image: url('/assets/shape-definefeelings-2.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      left: -6.6vw;
      top: 45vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20vw 30vw 0vw 10vw;
      z-index: 4;
      @media (min-width: $desktop-width) {
        width: 375px;
        height: 393px;
        left: -24px;
        top: 168px;
        padding: 75px 112px 0vw 37px;
      }
    }
    &.shape-definefeelings-3 {
      position: absolute;
      width: 106.6vw;
      height: 155vw;
      background-image: url('/assets/shape-definefeelings-3.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -6.6vw;
      top: 105vw;
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 35vw 10vw 0 15vw;
      z-index:3;
      @media (min-width: $desktop-width) {
        width: 399px;
        height: 581px;
        right: -24px;
        top: 393px;
        padding: 131px 37px 0 56px;
      }
    }

    &.shape-definefeelings-4 {
      position: absolute;
      width: 108.6vw;
      height: 185vw;
      background-image: url('/assets/shape-definefeelings-4.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      right: -8.6vw;
      top: 235vw;
  
      display: flex;
      justify-content: center;
      align-items: top;
      padding: 65vw 15vw 0 15vw;
      z-index:3;
      @media (min-width: $desktop-width) {
        width: 407px;
        height: 693px;
        right: -32px;
        top: 881px;
        padding: 243px 56px 0 56px;
      }
    }
  }
}

ion-router-link.home-card {
  font-family: "apercu-mono";
  font-weight: bold;
  font-size: 5vw;
  color: #000;
  display: flex;
  width: 100%;
  height: 40vw;
  margin: 6.6vw 0 0;
  flex-direction: column;
  justify-content: center;
  padding: 10vw;

  @media (min-width: $desktop-width) { 
    font-size: 18px;
    height: 150px;
    margin: 24px 0 0;
    padding: 37px;
  }

  &.bg-button-blob,
  &.bg-button-spikes,
  &.bg-button-squiggle {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/assets/button-blob.svg');
    &.bg-button-spikes {
      background-image: url('/assets/button-spikes.svg');
    }
    &.bg-button-squiggle {
      background-image: url('/assets/button-squiggle.svg');
    }
  }
}

.breathe-instructions {
  background-image: url('/assets/bg-breathe.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.boundaries-bottom {
  padding-bottom: 50vw !important;
  @media (min-width: $desktop-width) { 
    padding-bottom: 180px !important;
  }
}

.bg-black-spikes {
  position: relative;
  height: 180vw;
  margin-top: -50vw;
  padding-top: 50vw;
  color: #fff;
  z-index: 3;

  @media (min-width: $desktop-width) { 
    height: 675px;
    margin-top: -187px;
    padding-top: 187px;
  }


  &:after {
    width: 100vw;
    height: 160vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: " ";
    background-image: url('/assets/black-spikes.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: -1;

    @media (min-width: $desktop-width) { 
      width: 375px;
      height: 600px;
    }
  }
}

.bg-blob {
  color: #fff;
  height: 160vw;
  margin: 5vw -6.6vw 0;
  padding: 0 6.6vw;
  background-image: url('/assets/bg-blob.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $desktop-width) { 
    height: 600px;
    margin: 18px -24px 0;
    padding: 0 24px;
  }
}


.define-your-feelings, 
.prepare-list, 
.breathe, 
.affirm-your-worth, 
.boundaries,
.battle-shame {

  .intro, .make-a-list, .define-boundaries, .define-strategies {
    min-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: scroll;

    @media (min-width: $desktop-width) { 
      left: 0;
      right: 0;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 7.5vw;

        @media (min-width: $desktop-width) { 
          margin-bottom: 28px;
        }

        &:last-of-type {
          margin-bottom: 15vw;

          @media (min-width: $desktop-width) { 
            margin-bottom: 56px;
          }
        }
      }
    }
  }

  .conclusion {
    .activity-outcomes {
      &:after {
        content: " ";
        display: block;
        clear: both;
      }
    }
    ul.list, ul.feelings-list {
      list-style: none;
      font-family: "apercu-mono";
      color: #fff;
      margin: 15vw 0 0;
      padding: 0;

      @media (min-width: $desktop-width) {
        margin: 56px 0 0;
      }

      li {
        margin: 10vw 0 0;
        padding: 0;

        @media (min-width: $desktop-width) {
          margin: 37px 0 0;
        }

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
    p.boundaries, p.strategies {
      font-family: "apercu-mono";
      color: #fff;
      margin: 15vw 0 0;
      padding: 0;
      @media (min-width: $desktop-width) { 
        margin: 56px 0 0;
      }
    }
    a.edit-activity {
      font-family: "apercu-mono";
      display: block;
      margin: 10vw 0 10vw;
      text-align: right;
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      line-height: 1;
      border-bottom: 2px solid #000;
      float: right;

      @media (min-width: $desktop-width) { 
        margin: 37px 0;
      }
    }
  }
  .bg-green-blob {
    background-image: url('/assets/bg-green-blob.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-breathe {
    background-image: url('/assets/bg-breathe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .feelings-list, .list {
    margin-top: 10vw;
    margin-bottom: 5vw;
    @media (min-width: $desktop-width) {
      margin-top: 37px;
      margin-bottom: 18px;
    }
    

    &.sort {
      .feelings-list-item:nth-of-type(n+4) {
        opacity: 0.4; 
      }
    }
  }

  &.prepare-list {
    background-image: url('/assets/prepare-list-intro-bg.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
    background-position: center bottom;
  }
}

.define-your-feelings {
  .conclusion {
    .emotional-granularity-bg {
      background-image: url('/assets/define-feelings-conclusion-bg.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
}

.boundaries {
  .intro {
    position: relative;
    min-height: auto;
    background-image: url('/assets/boundaries-intro-bg.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .conclusion {
    > *:first-child {
      background-image: url('/assets/boundary-conclusion-bg.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center 80vw;
    }
  }
}

.battle-shame {
  .conclusion {
    .f-off {
      background-image: url('/assets/shame-conclusion-bg.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      color: #fff;
      height: 150vw;
      padding: 0 10vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -20vw;
      @media (min-width: $desktop-width) {
        height: 562px;
        padding: 0 37px;
        margin-top: -65px;
      }
    }
  }
}

.breathe {
  .huge-number {
    color: #36926D;
    font-size: 80vw;
    text-align: center;
    margin: 0 0 10vw;
    padding: 0;
    @media (min-width: $desktop-width) { 
      font-size: 300px;
      margin: 0 0 37px;
      text-align: left;
    }
  }

  .cycle-count {
    display: inline-block;
    padding: 2vw 3vw;
    margin-right: 4vw;
    background-image: url('/assets/breathe-counter-bg.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    font-family: "apercu-mono";
    font-size: 5.5vw;
    font-weight: bold;
    @media (min-width: $desktop-width) { 
      font-size: 20px;
      padding: 7.5px 11.25px;
      margin-right: 15px;
    }
  }
}

.affirm-your-worth {

  .intro {
    background-image: url('/assets/affirm-your-worth-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .affirmation-choices {

    ul.affirmations {
      list-style: none;
      padding: 5vw 0;
      margin: 0;
      @media (min-width: $desktop-width) { 
        padding: 18px 0;
      }

      li {
        padding: 7.5vw 5vw;
        margin: 2.5vw -6.6vw;

        @media (min-width: $desktop-width) { 
          padding: 28px 18px;
          margin: 9px -24px;
        }

        &.add-your-affirmation {
          text-align: center;
        }

        textarea.magic-text-area {
          @extend h2;
          min-height: auto;
          

          &::placeholder {
            opacity: 0.4;
          }
        }

        h2, textarea.magic-text-area {
          margin: 0;
        }
      }
    }
  }

  .breath, .say-affirmation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .breath {
    .huge-number {
      color: #36926D;
      font-size: 90vw;
      text-align: center;
      margin: 0 0 10vw;
      padding: 0;
      @media (min-width: $desktop-width) { 
        font-size: 337px;
        margin: 0 0 37px;
        text-align: left;
      }
    }

    &.emotional-granularity-bg .huge-number {
      color: #FFC5A4;
    }
    &.shame-bg .huge-number {
      color: #45D2C1;
    }
    &.neuroplasticity-bg .huge-number {
      color: #CE82E9;
    }
  }

  .conclusion {
    background-image: url('/assets/black-blob-bg.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    h1 {
      color: #fff;
      margin-bottom: 35vw;

      @media (min-width: $desktop-width) { 
        margin-bottom: 131px;
      }
    }
  }
}

.feelings-list-item, .list-item {
  transition: opacity 250ms ease-in;
  border-top: 1px #21F4D7 solid;
  border-bottom: 1px #21F4D7 solid;
  margin-bottom: -1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  label {
    width: 100%;
  }
  textarea.magic-text-area {
    min-height: auto;
  }

  .feeling-name, .list-item-name {
    width: 100%;
    background: transparent;
    border: 0;
    border-radius: 0;
    padding: 5vw;
    color: #fff;
    font-family: "apercu-mono";
    font-weight: bold;
    opacity: 1 !important;
    @media (min-width: $desktop-width) {
      padding: 18px;
    }

    &.list-item-name {
      padding: 0;
      margin: 5vw 0;
      @media (min-width: $desktop-width) {
        margin: 18px 0;
      }

    }

    &::placeholder {
      color: #fff;
      opacity: 0.4;
    } 
  }
}

button.add-item {
  background: transparent;
}

.page-navigation-wrapper {
  height: auto;
  background: #000;
  position: relative;
}
.page-navigation {
  position: absolute;
  bottom: 0;
  margin: 2.25vw 6.25vw;
  width: calc(100% - 12.5vw);
  height: 17.5vw;

  @media (min-width: $desktop-width) { 
    margin: 8px 23px;
    width: calc(100% - 46px);
    height: 65px;
    position: fixed;

  }

  a {
    display: block;
    width: 12vw;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $desktop-width) { 
      width: 45px;
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
      transform: rotate(180deg);
    }
  }
}

.fake-back-button {
  ion-icon {
    font-size: 31px;
  }
}

.learn-feelings {
  h1 {
    color: #fff;
  }
}

.full-height {
  min-height: 100%;
}

.hard-conversation-link {
  color: #fff;
}

.sc-ion-modal-md-h {
  height: 100% !important;
  --height: 100% !important;
}