/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: 'apercu';
  font-weight: normal;
  src: url('/assets/fonts/Apercu/apercu-regular.eot');
  src: url('/assets/fonts/Apercu/apercu-regular.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/apercu-regular.woff2') format('woff2'),
       url('/assets/fonts/Apercu/apercu-regular.woff') format('woff'),
       url('/assets/fonts/Apercu/apercu-regular.ttf') format('truetype'),
       url('/assets/fonts/Apercu/apercu-regular.svg') format('svg');
}

@font-face {
  font-family: 'apercu';
  font-weight: bold;
  src: url('/assets/fonts/Apercu/apercu-bold.eot');
  src: url('/assets/fonts/Apercu/apercu-bold.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/apercu-bold.woff2') format('woff2'),
       url('/assets/fonts/Apercu/apercu-bold.woff') format('woff'),
       url('/assets/fonts/Apercu/apercu-bold.ttf') format('truetype'),
       url('/assets/fonts/Apercu/apercu-bold.svg') format('svg');
}

@font-face {
  font-family: 'apercu';
  font-style: italic;
  src: url('/assets/fonts/Apercu/apercu-italic.eot');
  src: url('/assets/fonts/Apercu/apercu-italic.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/apercu-italic.woff2') format('woff2'),
       url('/assets/fonts/Apercu/apercu-italic.woff') format('woff'),
       url('/assets/fonts/Apercu/apercu-italic.ttf') format('truetype'),
       url('/assets/fonts/Apercu/apercu-italic.svg') format('svg');
}

@font-face {
  font-family: 'apercu';
  font-weight: bold;
  font-style: italic;
  src: url('/assets/fonts/Apercu/apercu-bold-italic.eot');
  src: url('/assets/fonts/Apercu/apercu-bold-italic.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/apercu-bold-italic.woff2') format('woff2'),
       url('/assets/fonts/Apercu/apercu-bold-italic.woff') format('woff'),
       url('/assets/fonts/Apercu/apercu-bold-italic.ttf') format('truetype'),
       url('/assets/fonts/Apercu/apercu-bold-italic.svg') format('svg');
}

@font-face {
  font-family: 'apercu';
  font-weight: 900;
  src: url('/assets/fonts/Apercu/apercu-Black.eot');
  src: url('/assets/fonts/Apercu/apercu-Black.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/apercu-Black.woff') format('woff'),
       url('/assets/fonts/Apercu/apercu-Black.ttf') format('truetype'),
       url('/assets/fonts/Apercu/apercu-Black.svg') format('svg');
}

@font-face {
  font-family: 'apercu-mono';
  font-weight: normal;
  src: url('/assets/fonts/Apercu/ApercuMono-Regular.eot');
  src: url('/assets/fonts/Apercu/ApercuMono-Regular.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/ApercuMono-Regular.woff2') format('woff2'),
       url('/assets/fonts/Apercu/ApercuMono-Regular.woff') format('woff'),
       url('/assets/fonts/Apercu/ApercuMono-Regular.ttf') format('truetype'),
       url('/assets/fonts/Apercu/ApercuMono-Regular.svg') format('svg');
}


@font-face {
  font-family: 'apercu-mono';
  font-weight: bold;
  src: url('/assets/fonts/Apercu/ApercuMono-Bold.eot');
  src: url('/assets/fonts/Apercu/ApercuMono-Bold.eot') format('embedded-opentype'),
       url('/assets/fonts/Apercu/ApercuMono-Bold.woff2') format('woff2'),
       url('/assets/fonts/Apercu/ApercuMono-Bold.woff') format('woff'),
       url('/assets/fonts/Apercu/ApercuMono-Bold.ttf') format('truetype'),
       url('/assets/fonts/Apercu/ApercuMono-Bold.svg') format('svg');
}

@font-face {
  font-family: 'GillSans';
  src: url('/assets/fonts/Gill-Sans/GillSans-UltraBold.ttf') format('truetype'),
      url('/assets/fonts/Gill-Sans/GillSans-UltraBold.svg#GillSans-UltraBold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/** Ionic CSS Variables **/
:root {

  --ion-font-family: 'apercu';

  /** primary **/
  --ion-color-primary: #4d84fa;
  --ion-color-primary-rgb: 77, 132, 250;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #4474dc;
  --ion-color-primary-tint: #5f90fb;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-padding: 6.6vw;
  @media (min-width: '1000px') { 
    --ion-padding: 30px;
  }
}

.metacognition {
  color: #E8B912;
}
.neuroplasticity {
  color: #FFC5A4;
}
.neuroplasticity-bg {
  background-color: #FFC5A4 !important;
}
.cognitive-load {
  color: #DC781A;
}
.learned-helplessness {
  color: #FA4F39;
}
.emotional-hijacking {
  color: #F5ABCF;
}
.emotional-hijacking-bg {
  background-color: #F5ABCF !important;
}
.emotional-granularity {
  color: #CE82E9;
}
.emotional-granularity-bg {
  background-color: #CE82E9 !important;
}
.vulnerability {
  color: #ADABD4;
}
.vulnerability-bg {
  background-color: #ADABD4 !important;
}
.shame {
  color: #4D84FA;
}
.shame-bg {
  background-color: #4D84FA !important;
}
.anger {
  color: #005FB5;
}
.anger-bg {
  background-color: #005FB5 !important;
}
.anxiety {
  color: #36926D;
}
.anxiety-bg {
  background-color: #36926D !important;
}
.self-criticism {
  color: #45D2C1;
}
.self-criticism-bg {
  background-color: #45D2C1 !important;
}
.boundaries {
  color: #9ABCA8;
}
.discomfort {
  color: #95ED5F;
}
.frustration {
  color: #E0CB0F;
}
.frustration-bg {
  background-color: #E0CB0F !important;
}
.self-regulation {
  color: #CAC067;
}
.white-bg {
  background-color: #fff;
}
.home-bg {
  background: #000 !important;

  h1, h2, h3, p {
    color: #fff !important;
  }
  

}

